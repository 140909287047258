exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deals-js": () => import("./../../../src/pages/deals.js" /* webpackChunkName: "component---src-pages-deals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matelas-js": () => import("./../../../src/pages/matelas.js" /* webpackChunkName: "component---src-pages-matelas-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-templates-comparer-js": () => import("./../../../src/templates/comparer.js" /* webpackChunkName: "component---src-templates-comparer-js" */),
  "component---src-templates-matelas-js": () => import("./../../../src/templates/matelas.js" /* webpackChunkName: "component---src-templates-matelas-js" */)
}

